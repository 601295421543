var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Change Icon", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "Sometimes we want to change the internal icon inside the checkbox. To do so, we have the property "
        ),
        _c("code", [_vm._v("icon")]),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "icon-pack": "feather", icon: "icon-check" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Primary")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-message-square",
                  color: "success",
                },
                model: {
                  value: _vm.checkBox2,
                  callback: function ($$v) {
                    _vm.checkBox2 = $$v
                  },
                  expression: "checkBox2",
                },
              },
              [_vm._v("SMS")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-x",
                  color: "danger",
                },
                model: {
                  value: _vm.checkBox3,
                  callback: function ($$v) {
                    _vm.checkBox3 = $$v
                  },
                  expression: "checkBox3",
                },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-paperclip",
                  color: "warning",
                },
                model: {
                  value: _vm.checkBox4,
                  callback: function ($$v) {
                    _vm.checkBox4 = $$v
                  },
                  expression: "checkBox4",
                },
              },
              [_vm._v("File")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-bold",
                  color: "dark",
                },
                model: {
                  value: _vm.checkBox5,
                  callback: function ($$v) {
                    _vm.checkBox5 = $$v
                  },
                  expression: "checkBox5",
                },
              },
              [_vm._v("Bold")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-map-pin",
                  color: "rgb(38, 187, 174)",
                },
                model: {
                  value: _vm.checkBox6,
                  callback: function ($$v) {
                    _vm.checkBox6 = $$v
                  },
                  expression: "checkBox6",
                },
              },
              [_vm._v("Location")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-camera",
                  color: "#720ea8",
                },
                model: {
                  value: _vm.checkBox7,
                  callback: function ($$v) {
                    _vm.checkBox7 = $$v
                  },
                  expression: "checkBox7",
                },
              },
              [_vm._v("Camera")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <ul class="demo-alignment">\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-check" v-model="checkBox1">Primary</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-message-square" color="success" v-model="checkBox2">SMS</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-x" color="danger" v-model="checkBox3">Cancel</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-paperclip" color="warning" v-model="checkBox4">File</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-bold" color="dark" v-model="checkBox5">Bold</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-map-pin" color="rgb(38, 187, 174)" v-model="checkBox6">Location</vs-checkbox>\n      </li>\n      <li>\n        <vs-checkbox icon-pack="feather" icon="icon-camera" color="#720ea8" v-model="checkBox7">Camera</vs-checkbox>\n      </li>\n    </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      checkBox1:true,\n      checkBox2:true,\n      checkBox3:true,\n      checkBox4:true,\n      checkBox5:true,\n      checkBox6:true,\n      checkBox7:true,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }