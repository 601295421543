var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Boolean Value", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "In most cases you will need a boolean data type while working with checkBoxes. For making this possible you have to link a boolean value in "
        ),
        _c("code", [_vm._v("v-model")]),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v(_vm._s(_vm.checkBox1))]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                model: {
                  value: _vm.checkBox2,
                  callback: function ($$v) {
                    _vm.checkBox2 = $$v
                  },
                  expression: "checkBox2",
                },
              },
              [_vm._v(_vm._s(_vm.checkBox2))]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="centerx">\n    <li>\n      <vs-checkbox v-model="checkBox1">' +
            _vm._s("{{ checkBox1 }}") +
            '</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox v-model="checkBox2">' +
            _vm._s("{{ checkBox2 }}") +
            "</vs-checkbox>\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      checkBox1: true,\n      checkBox2: false,\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }