var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "String Value", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "You can pass as a value a string by giving it as a value of the property "
        ),
        _c("code", [_vm._v("vs-value")]),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "vs-value": "Luis Daniel" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Luis Daniel")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "op-block" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.checkBox1 == null ? "null" : _vm.checkBox1) +
              "\n          "
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="demo-alignment">\n    <li>\n      <vs-checkbox v-model="checkBox1" vs-value="Luis Daniel">Luis Daniel</vs-checkbox>\n    </li>\n    <li class="op-block">\n      ' +
            _vm._s("{{ checkBox1==null?&apos;null&apos;:checkBox1 }}") +
            "\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      checkBox1: null,\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }