var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-element-checkbox-demo" } },
    [
      _c("checkbox-state"),
      _c("checkbox-color"),
      _c("checkbox-boolean-value"),
      _c("checkbox-string-value"),
      _c("checkbox-array-value"),
      _c("checkbox-array-object-value"),
      _c("checkbox-change-icon"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }