var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Array Values", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "You can have several checkBoxs linked to the same array only by using "
        ),
        _c("code", [_vm._v("v-model")]),
        _vm._v(" with a value of "),
        _c("strong", [_vm._v("array")]),
        _vm._v("."),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "vs-value": "luis" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Luis")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "vs-value": "carols" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Carols")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "vs-value": "summer" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Summer")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { "vs-value": "lyon" },
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Lyon")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "op-block" }, [
          _vm._v(
            "\n                " + _vm._s(_vm.checkBox1) + "\n            "
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="centerx">\n    <li>\n      <vs-checkbox v-model="checkBox1" vs-value="luis">Luis</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox v-model="checkBox1" vs-value="carols">Carols</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox v-model="checkBox1" vs-value="summer">Summer</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox v-model="checkBox1" vs-value="lyon">Lyon</vs-checkbox>\n    </li>\n    <li class="modelx">\n      ' +
            _vm._s("{{ checkBox1 }}") +
            "\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      checkBox1: ['luis']\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }