var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "State", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("To add a checkBox, we have the "),
        _c("code", [_vm._v("vs-checkbox")]),
        _vm._v(" component which expects a principal parameter "),
        _c("code", [_vm._v("v-model")]),
        _vm._v(" that would be our value."),
      ]),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Primary - active")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                model: {
                  value: _vm.checkBox2,
                  callback: function ($$v) {
                    _vm.checkBox2 = $$v
                  },
                  expression: "checkBox2",
                },
              },
              [_vm._v("Primary - inactive")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { disabled: "true" },
                model: {
                  value: _vm.checkBox3,
                  callback: function ($$v) {
                    _vm.checkBox3 = $$v
                  },
                  expression: "checkBox3",
                },
              },
              [_vm._v("Primary - active - disabled")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { disabled: "true" },
                model: {
                  value: _vm.checkBox4,
                  callback: function ($$v) {
                    _vm.checkBox4 = $$v
                  },
                  expression: "checkBox4",
                },
              },
              [_vm._v("Primary - inactive - disabled")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="centerx">\n    <li>\n      <vs-checkbox v-model="checkBox1">Primary - active</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox v-model="checkBox2">Primary - inactive</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox disabled="true" v-model="checkBox3">Primary - active - disabled</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox disabled="true" v-model="checkBox4">Primary - inactive - disabled</vs-checkbox>\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      checkBox1:true,\n      checkBox2:false,\n      checkBox3:true,\n      checkBox4:false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }