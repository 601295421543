var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Array Object values", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "Sometimes you need to link an object as a value when selecting a checkBox for it, you only have to put it as value in "
        ),
        _c("strong", [_vm._v("vs-value")]),
        _vm._v(" an object either literal or linked."),
      ]),
      _c(
        "ul",
        { staticClass: "demo-alignment" },
        _vm._l(_vm.regions, function (region, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "vs-checkbox",
                {
                  attrs: { "vs-value": region },
                  model: {
                    value: _vm.form.regions,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "regions", $$v)
                    },
                    expression: "form.regions",
                  },
                },
                [_vm._v(_vm._s(region.name))]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "op-block mt-5" }, [
        _vm._v(_vm._s(_vm.form.regions)),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <ul class="centerx">\n      <li v-for="(region, index) in regions" :key="index">\n        <vs-checkbox v-model="form.regions" :vs-value="region" >' +
            _vm._s("{{ region.name }}") +
            '</vs-checkbox>\n      </li>\n    </ul>\n\n    <div class="modelx">' +
            _vm._s("{{ form.regions }}") +
            "</div>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    form: {\n    regions: []\n  },\n  regions: [\n    { id: 1, name: 'Queensland' },\n    { id: 2, name: 'South Australia' },\n    { id: 3, name: 'New South Wales' },\n  ]\n})\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }