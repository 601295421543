var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("You can change the color of the checkBox with the property "),
        _c("code", [_vm._v("color")]),
        _vm._v(". You are able to use the Main Colors or "),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v(" colors."),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                model: {
                  value: _vm.checkBox1,
                  callback: function ($$v) {
                    _vm.checkBox1 = $$v
                  },
                  expression: "checkBox1",
                },
              },
              [_vm._v("Primary")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "success" },
                model: {
                  value: _vm.checkBox2,
                  callback: function ($$v) {
                    _vm.checkBox2 = $$v
                  },
                  expression: "checkBox2",
                },
              },
              [_vm._v("Success")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "danger" },
                model: {
                  value: _vm.checkBox3,
                  callback: function ($$v) {
                    _vm.checkBox3 = $$v
                  },
                  expression: "checkBox3",
                },
              },
              [_vm._v("Danger")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "warning" },
                model: {
                  value: _vm.checkBox4,
                  callback: function ($$v) {
                    _vm.checkBox4 = $$v
                  },
                  expression: "checkBox4",
                },
              },
              [_vm._v("Warning")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "dark" },
                model: {
                  value: _vm.checkBox5,
                  callback: function ($$v) {
                    _vm.checkBox5 = $$v
                  },
                  expression: "checkBox5",
                },
              },
              [_vm._v("Dark")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "rgb(38, 187, 174)" },
                model: {
                  value: _vm.checkBox6,
                  callback: function ($$v) {
                    _vm.checkBox6 = $$v
                  },
                  expression: "checkBox6",
                },
              },
              [_vm._v("Color RGB")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-checkbox",
              {
                attrs: { color: "#720ea8" },
                model: {
                  value: _vm.checkBox7,
                  callback: function ($$v) {
                    _vm.checkBox7 = $$v
                  },
                  expression: "checkBox7",
                },
              },
              [_vm._v("Color HEX")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="demo-alignment">\n    <li>\n      <vs-checkbox v-model="checkBox1">Primary</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="success" v-model="checkBox2">Success</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="danger" v-model="checkBox3">Danger</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="warning" v-model="checkBox4">Warning</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="dark" v-model="checkBox5">Dark</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="rgb(38, 187, 174)" v-model="checkBox6">Color RGB</vs-checkbox>\n    </li>\n    <li>\n      <vs-checkbox color="#720ea8" v-model="checkBox7">Color HEX</vs-checkbox>\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n    data() {\n        return {\n          checkBox1: true,\n          checkBox2: true,\n          checkBox3: true,\n          checkBox4: true,\n          checkBox5: true,\n          checkBox6: true,\n          checkBox7: true,\n        }\n    }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }